input[type="file"] {
  display: none;
}

@keyframes loading {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

.loading_bar {
  animation-duration: 2s;
  animation-name: loading;
  animation-iteration-count: infinite;
}

a:active {
  background: none;
}
a:hover {
  background: none;
}
a:active {
  background-color: none;
}

img {
  pointer-events: none;
}

video {
  pointer-events: none;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: white;
  width: 6px;
  height: 6px;
  border-radius: 15px;
}

::-webkit-scrollbar-thumb {
  background: #949ab2;
  border-radius: 15px;
}

.pdf_document {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  /* width: 100%; */
  position: relative;
}
